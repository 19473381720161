import React, { Component, useEffect } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faVimeo,
  faYoutube,
  faTwitter,
  faSoundcloud,
} from '@fortawesome/free-brands-svg-icons';
import CircleType from 'circletype';
import Faq from '../../components/faq/Faq';
import bird1 from '../../assets/images/birds/bird1.png';
import bird2 from '../../assets/images/birds/bird2.png';
import eye from '../../assets/images/icons/eye.svg';
import compass from '../../assets/images/icons/compass.svg';
import food from '../../assets/images/icons/food.svg';
import mouth from '../../assets/images/icons/mouth.svg';
import sound from '../../assets/images/icons/sound.svg';
import Experience from '../../components/experiences/experience';
// experiences
import sanctuary1 from '../../assets/images/experiences/wellness.jpg';

// transitionimage
import transitionimage from '../../assets/images/transition.jpg';
// travelines
import Travelline from '../../components/travellines/Travelline1';

// rotate text
import experienceSpinner from '../../assets/images/experiences/experience_spinner.svg';

const Page = styled.div`
  margin-top: 7.5vw;

  .siteWrapper {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-column: 1 / span 24;
    @media (min-width: 960px) {
      grid-column: 5 / span 16;
    }
    .experiences-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 960px) {
        grid-template-columns: repeat(1, 1fr);
      }
      grid-template-rows: masonry;
      grid-gap: 30px;
    }
  }
  .img {
    margin: 60px 0;
    grid-column: 2 / span 22;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
  }
  .text {
    margin: 60px 0;
    grid-column: 2 / span 22;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
  }
  header {
    grid-column: 2 / span 22;
    @media (min-width: 1100px) {
      grid-column: 5 / span 16;
    }

    padding: 3.5vw 0 0 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .back {
      margin-bottom: 20px;
      display: block;
      font-size: 1em;
    }
    .intro-text {
      grid-column: 1 / span 4;
      @media (min-width: 768px) {
        grid-column: 1 / span 3;
      }
      .title {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-top: 5px;
      }
      .sub-heading {
        font-size: 1.2rem;
      }
      h1 {
        font-size: 3em;
        padding-bottom: 10px;
      }
      margin-bottom: 40px;
    }
    .top-right {
      grid-column: 6 / span 1;
      @media (min-width: 768px) {
        grid-column: 4 / span 6;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .circle-rotate {
        position: absolute;
        font-size: 0.7em;
        color: var(--olive);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        #circle-text {
          width: 130px;
          height: 130px;
          animation: spin 60s infinite linear;
          max-width: none;
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
      img {
        width: 60px;
      }
    }
    .right {
      grid-column: 1 / span 6;
      display: flex;
      flex-direction: row;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      .img1 {
        flex: 1;

        img {
          display: block;
        }
      }
    }
  }

  // min-height: 100vh;
`;

const Bird2 = styled.div`
  position: absolute;
  left: -70px;
  top: 0%;
  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
`;

const Bird3 = styled.div`
  position: absolute;
  right: -70px;
  top: 400px;
  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
`;
const ExperiencesStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: masonry;
  grid-gap: 0;
`;

const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Experiences() {
  useEffect(() => {});
  return (
    <Page>
      <Bird2>
        <img src={bird2} alt="" />
      </Bird2>
      <Bird3>
        <img src={bird1} alt="" />
      </Bird3>
      <div className="SiteContainer">
        <div className="siteWrapper">
          <header>
            <div className="intro-text">
              <AniLink
                className="link back b-i"
                to="/experiences"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Back to Experiences
              </AniLink>
              <h1>The Magnetic Sanctuary x Vitality Hours</h1>
              <p className="sub-heading b-i">
                Magnetic Sanctuary is a tranquil retreat within the festival
                where you can revive and revitalise your body and mind through a
                variety of wellness workshops.
              </p>
            </div>
            <div className="top-right">
              <div className="circle-rotate">
                <img
                  id="circle-text"
                  className="sb"
                  src={experienceSpinner}
                  alt=""
                />
              </div>
              <img src={eye} alt="" />
            </div>
            <div className="right">
              <div className="img1">
                {' '}
                <img src={sanctuary1} alt="" />
              </div>
            </div>
          </header>

          <div className="text">
            <p>
              Magnetic Sanctuary is a tranquil retreat within the festival where
              you can revive and revitalise your body and mind through a variety
              of wellness workshops.
            </p>
            <p>
              An informal space open to all guests bringing ancient knowledge
              and wellness practises to Magnetic Nomads. A sanctuary to relax,
              unwind, focus inwards and let go. A sensory space drenched in
              peace and serenity.
            </p>
            <p>
              Together with Magnetic Fields,{' '}
              <span className="b">VITALITY HOURS</span> have designed this
              unique experience in order to encourage exploration, learning and
              growth in order to achieve the best, most glorious versions of
              ourselves. Expect ayurvedic tea ceremonies, dynamic breathwork &
              biohacking, mindful bodywork for trauma & pain, sleep meditation,
              kundalini yoga, guided meditation, detox foot soaks, massages,
              superfood shots, healing circles and more.
            </p>
            <p>
              Over the past three years and over the course of nine successful
              wellness events, VITALITY HOURS has weaved together a community of
              seekers who are interested in connecting more deeply with
              themselves and with others around them.
            </p>
            <p className="b-i">Our practitioners:</p>
            <p>
              <span className="b">Avantika Kochhar</span>: A trained classical
              ballet dancer, and yogi. Her teachings revolve around Vinyasa
              Yoga, experiential anatomy, physical rehabilitation and therapy,
              and Somatic movement. She has over a decade of experience has
              taught at festivals such as Wonderfruit, in Thailand as well as
              universities and studios across India, China, Europe, USA and the
              UK.
            </p>
            <p>
              <span className="b">Alisha Berry</span>: A spiritual teacher,
              channel and energy alchemist. She works with various different
              modalities in the energy healing space, such as crystals, oracles,
              spells amongst other tools for self-transformation.
            </p>
            <p>
              <span className="b">Nikhil Bhushan</span>: A professional athlete
              and life coach whose practice encompasses yoga & meditation
              therapy, nutritional guidance and physical training. He is a
              trained yoga instructor in the lineage of Sri Aurobindo's wisdom
              and teachings, his style of teaching is a mix of Hatha, Vinyasa,
              Yin, restorative yoga. In addition, he is a nutritionist certified
              from the Tim Noakes Foundation.
            </p>
            <p>
              <span className="b">Shalini Sarena</span>: A Kundalini yoga and
              meditation teacher with experience teaching across the Asian
              sub-continent, the USA and UK.
            </p>
            ++
            <p>
              VITALITY HOURS was founded by Nadia & Carol Singh in 2017, it was
              a passion project for the duo to build an authentic community that
              collectively aspires to experience new practices and guides in the
              self-care and well-being space.
            </p>
            <p>
              Nadia & Carol co-founded ANTIDOTE in 2013, India's first
              functional beverage & detox brand that introduced the concept of
              modern-fasting for health, plant-based milks and cold-pressed
              juicing to the urban Indian audience.
            </p>
            <a
              target="_blank"
              className="sb-i"
              href="https://www.instagram.com/VitalityHours/"
            >
              <FontAwesomeIcon icon={faInstagram} />
              &nbsp;@VitalityHours
            </a>
            <br />
            <a
              target="_blank"
              className="sb-i"
              href="https://www.facebook.com/ANTIDOTEdrink"
            >
              <FontAwesomeIcon icon={faFacebook} />
              &nbsp;@antidotedrink
            </a>
            <br />
            <a
              target="_blank"
              className="sb-i"
              href="https://www.youtube.com/channel/UCbYX1MiQoMbNy0Yurnd8CqA"
            >
              <FontAwesomeIcon icon={faYoutube} />
              &nbsp;@VitalityHoursbyantidote
            </a>
          </div>
        </div>
      </div>
    </Page>
  );
}
